/* File Sharing View CSS */

.action {
	margin-right: 1em;
	transition: 0.3s;
}

.action:hover {
	/* move color defs to use core-ui colors */
	color: #999999;
}

.listing-table td > .badge {
	margin-right: 0.5em;
	font-size: 0.8em;
	font-weight: 700;
}

/* date picker (move to global?) */
.react-datepicker__input-container > input[type='text'] {
	padding: 5px;
	border: 1px solid #ccc;
	border-radius: 4px;
}
