// headings
@mixin h1Fonts {
	font-family: $base-font;
	font-size: $fsH1;
}

@mixin h2Fonts {
	font-family: $base-font;
	font-size: $fsH2;
}

@mixin h3Fonts {
	font-family: $base-font;
	font-size: $fsH3;
}

// text
@mixin textBody {
	font-family: $base-font;
	font-size: $fsTextBody;
	line-height: 20px;
	text-rendering: optimizeLegibility;
	margin-bottom: 0.5rem;
}

@mixin boxShadow {
	box-shadow: 3px 5px 16px rgba(127, 127, 127, 0.15);
}

@mixin borderRadius {
	border-radius: $border-radius;
}

/* Light/Dark Mode */

@mixin lightmode() {
	--text-color: #222;
	--link-color: #9f0dcd;
	--background-color: #f5f5f5 !important;
	--card-background-color: #fff;
	--box-shadow: 0 2.8px 2.2px rgba(255, 255, 255, 0.02),
		0 6.7px 5.3px rgba(255, 255, 255, 0.02),
		0 12.5px 10px rgba(255, 255, 255, 0.02),
		0 22.3px 17.9px rgba(255, 255, 255, 0.02),
		0 41.8px 33.4px rgba(255, 255, 255, 0.046),
		0 100px 80px rgba(255, 255, 255, 0);
	--border: #ccc;
	--selected: #eee;
	--table-row: #eee;
	--template-hours: #05505c;
	--template-sidebar: #f5f5f5;
	--modal-background: #fff;
	--scrollbar-background: #eee;
	--scrollbar-color: #6c757d;
}

@mixin darkmode() {
	--text-color: #f5f5f5;
	--link-color: #faf697;
	--background-color: #131313 !important;
	--card-background-color: #202020;
	--box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
		0 6.7px 5.3px rgba(0, 0, 0, 0.02), 0 12.5px 10px rgba(0, 0, 0, 0.02),
		0 22.3px 17.9px rgba(0, 0, 0, 0.02), 0 41.8px 33.4px rgba(0, 0, 0, 0.046),
		0 100px 80px rgba(0, 0, 0, 0);
	--border: #222;
	--selected: #494949cf;
	--table-row: #4b4b4b;
	--template-hours: #05505c;
	--template-sidebar: #4b4b4b;
	--modal-background: #222;
	--scrollbar-background: #6c757d;
	--scrollbar-color: #eee;
}
