/* BASE TYPE STYLES */

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300&display=swap');

$base-font: 'Roboto', Arial, sans-serif;
$alt-font: 'Roboto Slab', serif;

body {
	font-family: $base-font;
	-moz-osx-font-smoothing: subpixel-antialiased;
	-webkit-font-smoothing: subpixel-antialiased;
	font-smooth: subpixel-antialiased;
}

// font sizes
$fsH1: 23px;
$fsH2: 16px;
$fsH3: 15px;
$fs16: 16px;
$fs14: 14px;
$fs12: 12px;

$fsTextBody: $fs14;

.alt-font {
	font-family: $alt-font;
}
