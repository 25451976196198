@import 'normalize.css';
@import 'common/Vars';
@import 'common/Breakpoints';
@import 'common/Colors';
@import 'common/Fonts';
@import 'common/Mixins';
@import 'common/BootstrapOverrides';
@import 'common/Rome-UI';

.navwrapper ~ div {
	margin-top: 116px;
}

@mixin defaults() {
	--darkGrey: #989898;
	--lightGrey: #eee;
	--white: #fff;
	--card-background-color: #fff;
}

body {
	@include defaults();
}

html,
body,
footer {
	height: 100%;
	position: relative;
	font-family: $base-font;
	font-size: $fsTextBody;
	background-color: $background-light;
	color: $text-default;
}

@mixin darkmode() {
	--text-color: #f5f5f5;
	--link-color: #faf697;
	--background-color: #222 !important;
	--card-background-color: #343642;
	--box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
		0 6.7px 5.3px rgba(0, 0, 0, 0.02), 0 12.5px 10px rgba(0, 0, 0, 0.02),
		0 22.3px 17.9px rgba(0, 0, 0, 0.02), 0 41.8px 33.4px rgba(0, 0, 0, 0.046),
		0 100px 80px rgba(0, 0, 0, 0);
	--border: #343642;
	--selected: #494949cf;
	--table-row: #4b4b4b;
	--template-hours: #05505c;
	--template-sidebar: #4b4b4b;
	--modal-background: #343642;
	--scrollbar-background: #6c757d;
	--scrollbar-color: #eee;
}

a {
	color: var(--link-color);
	text-decoration: underline;
	text-decoration-style: dotted;
	text-decoration-color: #ccc;
	text-underline-offset: 1px;
}

hr {
	border-color: $accent;
}

button {
	background-color: #fff;
	border-color: $accent;
}
/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}
/* add more elements here to apply custom core-ui box-shadow */

.uppy-DragDrop-label,
.alert p,
.alert svg {
	color: #222 !important;
}

/* add more elements here to apply custom core-ui border */
// .card,
// #buttonUntitledStage {
// 	border: 1px solid var(--border);
// }

.container {
	padding-top: 10px;
	padding-bottom: 10px;
}

.check-icon {
	color: #008032 !important;
}

.error-icon {
	color: #ff2223 !important;
}

// .idbZgw,
// /* toast and select*/
// .toast,
// select,
// .bgiSSb,
// /* nav */
// nav,
// /* cards and custom cards */
// .card,
// .card-header,
// .card-footer,
// .dUJluY .card-body,
// /* Tables and footer */

// footer,
// /* calendar widget */
// .calendar-tile:disabled:not(.react-calendar__month-view__days__day--neighboringMonth),
// .popover-header,
// .popover-body,
// .izidCt,
// .fkeaNs,
// .kMsDru,
// .nav-tabs .nav-link.active,
// .input-group>.input-group-prepend>.input-group-text,
// /* stage owner select */
// #buttonUntitledStage,
// .options-menu-container {
// 	background-color: var(--card-background-color) !important;
// 	color: var(--text-color) !important;
// }

// /* custom select options */
// button.sc-hmzhuo.jkTtgS {
// 	background-color: var(--background-color) !important;
// 	color: var(--text-color) !important;

// 	&:hover {
// 		background-color: var(--table-row) !important;
// 	}
// }

// /* Target custom modals */
// .iBFngn,
// .jnCXOE,
// .modal-header,
// .modal-body,
// .modal-footer {
// 	background: var(--modal-background);
// 	color: var(--text-color);
// }

// .dUJluY,
// .eenRpZ {
// 	color: #222;
// 	background: #fff;
// }

// /* Tables */

// table tbody tr {
// 	background-color: var(--card-background-color) !important;

// 	&:hover {
// 		background-color: var(--table-row) !important;
// 	}
// }

// table tbody tr.selected td {
// 	background-color: var(--selected) !important;
// }

// // collections select
// .Select-control,
// .Select-control:hover,
// .Select.is-focused > .Select-control,
// .Select.is-searchable.is-open > .Select-control {
// 	height: 90px;
// 	padding: 9px 14px;
// 	color: var(--text-color);
// 	background-color: var(--card-background-color);
// 	background: var(--card-background-color);
// }
// .Select-value {
// 	padding: 9px 14px;
// 	background: var(--template-hours) !important;
// }

// .button-icon {
// 	color: var(--text-color) !important;
// }

// table,
// table thead tr th,
// table tbody tr td,
// div[role='combobox'],
// ul[role='listbox'],
// .bxEIun {
// 	color: var(--text-color);
// 	background-color: var(--card-background-color);
// 	background: var(--card-background-color);
// }

// table > body > tr:hover,
// figure.workflow-table-background-image {
// 	background-color: var(--table-row) !important;
// }

// .text-muted,
// .nav-item,
// .nav-item a,
// .bwyaUD,
// button.page-item.sides > svg,
// .text-dark,
// .share-link,
// p {
// 	color: var(--text-color) !important;
// }

// /* custom buttons */
// button.eVRmzr,
// button.jtiQKP,
// .kqWSWR.roadblocked,
// button.exPLlL,
// /* more custom buttons */
// .hwSxry,
// .jPPpnZ,
// .fsYEDX .active,
// .btn-danger,
// /* Sweet alert buttons */
// .swal2-confirm.swal2-styled,
// .swal2-cancel.swal2-styled,
// .gTvKxf,
// .kwGABy,
// .close-icon,
// .ffCNoq,
// .ffCNoq svg {
// 	color: #fff !important;

// 	svg {
// 		color: #fff !important;
// 	}
// }

// a > svg {
// 	color: (#fff);
// }

// div {
// 	color: var(--text-color);
// }

// .list-group,
// .list-group-item,
// input,
// button.field-button,
// .iWDpBi,
// .search-field,
// .btn-light,
// textarea,
// .form-control,
// input.form-control:disabled,
// .input-group > .input-group-append > .input-group-text,
// .react-datepicker__input-container > input[type='text'],
// .dSoRZY,
// .cXPAIq,
// .bg-white,
// .btn-secondary,
// .Select-placeholder,
// .Select-menu-outer,
// .react-calendar__navigation__label,
// .react-calendar__navigation__arrow,
// .container {
// 	color: var(--text-color) !important;
// 	background-color: var(--card-background-color) !important;
// 	background: var(--card-background-color) !important;
// }

/* WORKFLOW TEMPLATE DARK / LIGHT STYLES */

.UNxGJ,
.template-container,
.stage-editor {
	color: var(--text-color) !important;
	background-color: var(--table-row) !important;
	background: var(--table-row) !important;
	margin-top: 30px;
}

/* Template sidebar */
.jYVnjb {
	background-color: var(--template-sidebar) !important;
	background: var(--template-sidebar) !important;
}

.frFWtn {
	background-color: #416c65 !important;
	color: #fff !important;
}

/* dropdown items */
a.dropdown-item,
button.dropdown-item,
button.bDyGWT svg {
	background-color: #fff;
	color: #222 !important;

	svg {
		background-color: #fff;
		color: #222;
	}
}

p.hours {
	color: var(--template-hours) !important;
}

.react-calendar__tile--now {
	background-color: $text-default !important;
	&:hover {
		background-color: lighten($text-default, 0.7) !important;
	}
}

.react-calendar {
	border: none;
	border-radius: 0.25rem;
	width: 100% !important;
	background-color: var(--card-background-color) !important;
	color: var(--text-color) !important;
}

.react-datepicker-wrapper {
	width: 100%;
}

.terms-and-conditions {
	text-align: center;
	margin-left: 109rem;
	// Extra small devices (portrait phones, less than 576px)
	@media (max-width: 575.98px) {
		text-align: center;
		margin-left: 20rem;
	}

	// Small devices (landscape phones, 576px and up)
	@media (min-width: 576px) and (max-width: 767.98px) {
		text-align: center;
		margin-left: 30rem;
	}

	// Medium devices (tablets, 768px and up)
	@media (min-width: 768px) and (max-width: 991.98px) {
		text-align: center;
		margin-left: 50rem;
	}

	// Large devices (desktops, 992px and up)
	@media (min-width: 992px) and (max-width: 1199.98px) {
		text-align: center;
		margin-left: 50rem;
	}

	// Extra large devices (large desktops, 1200px and up)
	@media (min-width: 1200px) and (max-width: 1500px) {
		text-align: center;
		margin-left: 74rem;
	}
}
