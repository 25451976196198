/* notification elements */

@import '../../../core-ui/styles/App.scss';

.notifications-menu__toggle {
	width: 24px;
	height: 24px;
	position: relative;
	border: none;
	background-color: transparent;

	&:focus {
		box-shadow: none;
	}

	&:focus-visible {
		outline: none;
	}
}

.notification-enter {
	opacity: 0.01;
}

.notification-enter.notification-enter-active {
	opacity: 1;
	transition: 700ms;
}

.notification-leave {
	opacity: 1;
}

.notification-leave.notification-leave-active {
	opacity: 0.01;
	transition: 700ms;
}

.notifications-menu__icon {
	position: absolute;
	left: 0;
	top: 11px;
	font-size: 20px;
	line-height: 22px;
	color: $darkGrey;
	transform-origin: top center;
	transition: color 1s;

	&.has-notifications {
		color: $primary;
		animation: ring 2.2s ease-in-out;
		animation-delay: 1s;
		animation-iteration-count: infinite;
	}
}

.notifications-menu__count {
	position: absolute;
	top: -8px;
	right: 5px;
	display: flex;
	align-items: center;
	width: 22px;
	height: 22px;
	font-size: 10px;
	color: #ffffff;
	line-height: 1;
	background-color: #dc3545;
	border-radius: 50%;
	justify-content: center;
}

@keyframes ring {
	0% {
		transform: rotate(0deg);
	}

	4% {
		transform: rotate(35deg);
	}

	15% {
		transform: rotate(-30deg);
	}

	20% {
		transform: rotate(25deg);
	}

	30% {
		transform: rotate(-20deg);
	}

	40% {
		transform: rotate(15deg);
	}

	50% {
		transform: rotate(-10deg);
	}

	60% {
		transform: rotate(8deg);
	}

	70% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(0deg);
	}
}
