/* General ROME UI Styles */
input {
	border-radius: $border-radius;
}

.inactive {
	color: $inactive;
}

.small-cta-link {
	font-size: $fsTextBody;
	color: var(--link-color);

	svg {
		color: var(--text-color);
	}

	&:hover {
		text-decoration: none;

		.cta-icon {
			animation: pulse 1s infinite ease-in-out;
		}

		@keyframes pulse {
			0% {
				transform: translateX(0px);
			}

			50% {
				transform: translateX(6px);
			}

			100% {
				transform: translateX(0px);
			}
		}
	}
}

.cta-label {
	line-height: 1;
	vertical-align: middle;
}

.cta-icon {
	margin-left: 4px;
	margin-top: 1px;
	vertical-align: middle;
}

.page-subheading {
	padding-bottom: 0.5em;
}

td > .badge {
	font-size: 1em;
}

/* MAIN NAVIGATION */
.navwrapper {
	position: fixed;
	width: 100%;
	background-color: #fff;
	z-index: 99;

	.dropdown-toggle.nav-link {
		display: flex;
		align-items: center;
	}

	.dropdown-menu {
		@include boxShadow();
	}
}

.navbar__user-menu {
	.dropdown-toggle.nav-link::after {
		margin-left: 0.7rem;
		top: 50%;
	}
}

/* FILESHARING */

.share-link {
	color: $text-default;

	&:hover {
		color: $text-default;
	}
}

.table tr {
	transition: background-color, 0.3s;

	&:hover {
		background-color: $lighterGrey;
	}
}

/* typography stuff */

.text-large {
	font-size: 2em;
}

.text-small {
	font-size: 0.8em;
}

/**
 * Cards and Rome Cards 
 */

.card {
	border-radius: $border-radius;
}

.contained {
	margin: 15px;
}

.card-header {
	margin-bottom: 0;
	font-size: 1.3em;
	font-weight: 500;
}

.rome-card {
	border-radius: $border-radius;
	overflow: hidden;
	flex-direction: column;

	.card-header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		.card-header__title {
			font-family: $alt-font;
			font-size: 14px;
			font-weight: 700;
			color: $darkerGrey;
			text-transform: uppercase;
			letter-spacing: 2px;
		}

		.page-subheading {
			max-width: 80%;
			padding-bottom: 0;
		}

		.svg-inline--fa {
			color: lighten($text-default, 40%);
		}
	}

	.card-body {
		background-color: #fff;
		position: relative;
	}

	// .card-footer {}
}

.rome-dashboard {
	.row {
		padding-bottom: 1rem;

		.card + .card {
			margin-top: 1rem;
		}
	}
}

.welcome-message {
	padding-bottom: 0.5em;
	font-size: 20px;
}

/* dashboard link tiles */
.dashboard-link-tile {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2em;
	background-color: #fff;
	border: 2px solid #ddd;
	border-radius: 5px;
	width: 100%;
	height: 100%;

	transition: all 0.3s;

	&.welcome {
		background-color: #999;
		justify-content: center;

		p {
			color: #fff;
		}
	}

	.icon {
		display: block;
		margin: 3rem;
		font-size: 10rem;
		color: #ddd;
	}

	.label {
		display: block;
		font-size: 1.5em;
		color: #999;
	}

	.greeting {
		text-align: center;
		font-size: 2.5rem;
		line-height: 1.5;
		font-weight: 100;
		font-family: $alt-font;
	}

	&:hover {
		background-color: #999;

		.label {
			color: #fff;
		}
	}
}

/* WORKFLOWS and STAGES */

.template-sidebar.collapsed {
	.toggle-sidebar {
		right: -28px;
		background-color: #fff;
	}
}

// UI Elements

.modal__close-button {
	font-size: 1em;

	&.top-right {
		position: absolute;
		top: 8px;
		right: 8px;
	}

	&:hover {
		color: $danger !important;
	}

	:focus,
	:active {
		outline: none;
	}
}
