/* RM Carousel Custom Styles */

.card__resource-expertise {
	@media (min-width: 1200px) {
		max-height: 200px;
		overflow: hidden;
	}

	.page-subheading {
		padding-bottom: 0;
	}

	.card-body {
		display: flex;
		padding: 0 1em 0 1em;
	}
}

.rec.rec-carousel {
	flex: auto;
}

.rec.rec-slider-container {
	display: flex;
	align-items: center;
}

.rec.rec-carousel-item {
	display: flex;
	flex-direction: column;
	height: 8em;
	text-align: center;
	justify-content: center;

	h1 {
		padding-bottom: 0;
		font-size: 1.2em;
	}
}

.rec-pagination {
	padding-bottom: 1rem;

	.rec.rec-dot {
		height: 8px;
		width: 8px;
		background-color: #ddd;
		box-shadow: unset;

		&.rec-dot_active {
			background-color: #999;
		}
	}
}

.rec.rec-arrow {
	min-height: 20px;
	min-width: 20px;
	height: 20px;
	width: 20px;
	font-size: 1em;
	line-height: 1em;
	box-shadow: unset;
	background-color: #ddd;
}
