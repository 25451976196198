@import '../../../../node_modules/bootstrap/scss/bootstrap';

$RESOLUTION_MAX_WIDTH: 1980px;

// target resolution / max-width for containers
.container-fluid,
.container {
	max-width: $RESOLUTION_MAX_WIDTH;

	&.template-container {
		max-width: $RESOLUTION_MAX_WIDTH;
	}

	padding-right: 25px !important;
	padding-left: 25px !important;

	@media only screen and (max-width: $breakpointSm) {
		&.px-5 {
			padding-right: 15px !important;
			padding-left: 15px !important;
		}
	}
}

/* Algolia Select Override */
.ais-HitsPerPage-select {
	padding: 5px;
	border-radius: 6px;
}

// Typography
h1 {
	@include h1Fonts();
}

h2 {
	@include h2Fonts();
}

h3 {
	@include h3Fonts();
}

p,
.form-control {
	@include textBody();
}

small {
	line-height: 1 !important;
}

/* visual properties */
.no-shadow {
	box-shadow: none !important;
}

// Nav
.navbar {
	padding: 0.5em 0;
	box-shadow: 2px 4px 24px rgba(0 0 0 / 10%);

	@media only screen and (max-width: $breakpointMd) {
		margin-bottom: 2em;
		padding: 0.5em 10px;
	}

	@media only screen and (max-width: $breakpointSm) {
		> .container-fluid,
		> .container {
			padding-right: 0 !important;
			padding-left: 0 !important;
		}
	}
}

.navbar-brand {
	margin-right: 2em;
}

.nav-item {
	display: flex;
	align-items: center;
}

.nav-link,
.dropdown-item {
	@include textBody();
	margin-bottom: 0;
	cursor: pointer;
}

// Forms
.dropdown-item.active,
.dropdown-item:active {
	background-color: $lightGrey;
}

// Colors
.bg-lightred {
	background-color: #dc354533 !important;
}

// UI
.card {
	@include boxShadow;

	.card-title {
		font-size: 14px;
		line-height: 1.4;
	}

	.card-header {
		padding: 0.75rem;
		background-color: $secondaryColorLight;
	}

	.card-body {
		border-radius: 0.25em;
	}
}

.table {
	border-top: 0;

	thead {
		border-bottom: 0;
		background-color: $lightestGrey;
		color: $darkGrey;
		letter-spacing: 2px;
		text-transform: uppercase;
		font-size: 10px;
		font-weight: normal;

		th {
			border-bottom: 1px solid #dee2e6;
			border-top: 0;
			font-family: $alt-font;

			p {
				font-family: $base-font;
				font-weight: initial;
			}
		}
	}
}

.bg-grey {
	background-color: $grey;
}

.btn-outline-grey {
	color: $grey;
	border-color: $grey;

	&:hover {
		color: $white;
		background-color: $grey;
	}
}

.dropdown-item:hover,
.dropdown-item:focus {
	color: #212529 !important;
}

.badge {
	font-weight: normal;
}

.alert-dismissible .close {
	padding: 0.5rem 1.25rem;
}

.overflow-scroll {
	overflow: scroll;
}

.popover > .arrow::after,
.popover > .arrow::before {
	display: none;
}

.alert {
	z-index: 0;
}

/* Material UI Base overrides */

.MuiTableCell-root {
	font-size: 1rem;
}

.MuiTableCell-head {
	font-family: $alt-font;
}
