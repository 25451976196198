* {
	font-family: Roboto;
}

.content h1 {
	font-weight: 500;
	text-align: center;
}

svg#donut-chart {
	display: block;
	margin: 0 auto;
}

text.inner-circle {
	font-weight: 400;
	font-size: 20px;
	text-transform: uppercase;
}

.arc {
	cursor: pointer;

	&:hover {
		opacity: 0.85;
	}

	text {
		font-weight: 300;
		font-size: 11px;
		color: #fff;
	}
}
