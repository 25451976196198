// avatar styles
@mixin disabled-user {
	font-family: 'Material Icons';
	font-size: 2.5rem;
	color: #c00;
	display: block;
	width: 2.5rem;
	height: 2.5rem;
	position: absolute;
	top: 0;
	left: 0;
	line-height: 1;
}

.user-avatar {
	.disabled-user {
		@include disabled-user;
	}
}
